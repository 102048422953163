import Cip30 from './cip30';

class FlintApi extends Cip30 {
	// eslint-disable-next-line class-methods-use-this
	isInstalled() {
		return !!window?.cardano?.flint;
	}

	async isEnabled() {
		const isEnabled = await window.cardano.flint.isEnabled();
		console.log('isEnabled', isEnabled);
		if (isEnabled) {
			this.api = await window.cardano.flint.enable();
		}
		return isEnabled;
	}

	async enable() {
		this.api = await window.cardano.flint.enable();
	}
}

export default FlintApi;
