//@ts-nocheck
import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router, redirect } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './styles/styles.scss';
import { Provider } from 'react-redux';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';

import store from './redux/store';

import './i18n';

import './customize-styles/index.scss';
import './customize-styles/tailwind.css';

import { LoadingContext } from './useLoading';
import FullPageLoading from './components/FullPageLoading';

function Children() {
	const [loading, setLoading] = React.useState(false);

	return (
		<Router>
			<ThemeContextProvider>
				<LoadingContext.Provider value={{ setLoading }}>
					<Provider store={store}>
						<App />
						{loading && <FullPageLoading />}
					</Provider>
				</LoadingContext.Provider>
			</ThemeContextProvider>
		</Router>
	);
}

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container).render(<Children />); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
