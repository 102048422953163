import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import appReducer from './mainSlice';
import userReducer from './userSlice';
import voteSlice from './voteSlice';
import depositSlice from './depositSlice';

const store = configureStore({
	reducer: {
		app: appReducer,
		user: userReducer,
		vote: voteSlice,
		deposit: depositSlice,
	},
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
