import Cip30 from './cip30';

class NamiApi extends Cip30 {
	// eslint-disable-next-line class-methods-use-this
	isInstalled() {
		return !!window?.cardano?.nami;
	}

	async isEnabled() {
		const isEnabled = await window.cardano.nami.isEnabled();
		if (isEnabled) {
			this.api = await window.cardano.nami.enable();
		}
		return isEnabled;
	}
  
	async enable() {
		this.api = await window.cardano.nami.enable();
	}
}

export default NamiApi;
