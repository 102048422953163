import { createSlice } from '@reduxjs/toolkit';
import { QUESTION_TYPE } from 'pages/vote/VoteStatus/VoteList/VoteList';

const initialState = {
	metadata: null,
	votingPower: null,
	answers: {},
};

const voteSlice = createSlice({
	name: 'vote',
	initialState,
	reducers: {
		setMetadata: (state, { payload }) => {
			state.metadata = payload;
		},
		setVotingPower: (state, { payload }) => {
			state.votingPower = payload;
		},
		setAnswers: (state, { payload }) => {
			const { value, question } = payload;
			const questionKey = question.question_short_text;
			if (question.question_type === QUESTION_TYPE.SINGLE_CHOICE) {
				state.answers[questionKey] = value;
				return;
			}
			if (!state.answers[questionKey]) {
				state.answers[questionKey] = [];
			}
			if (question.question_type === QUESTION_TYPE.MULTIPLE_CHOICE) {
				const isSelected = state.answers[questionKey].includes(value);
				if (isSelected) {
					state.answers[questionKey] = state.answers[
						questionKey
					].filter((item) => item !== value);
					return;
				}
				if (
					state.answers[questionKey].length < question.choose_at_most
				) {
					state.answers[questionKey].push(value);
				}
			}
		},
	},
});

export const { setMetadata, setVotingPower, setAnswers } = voteSlice.actions;

export default voteSlice.reducer;
