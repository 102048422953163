import React from 'react';
import { useLocation } from 'react-router-dom';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { homeMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';

import { ReactComponent as MCoin } from './asset/mcoin.svg';

const SummaryHeader = ({title, showWallet = true, imgSrc}) => {
	const deviceScreen = useDeviceScreen();

	const location = useLocation();

	// console.log('>>>>>>>>>>>>>>>>>>>>>>>>', location.pathname);

	return (
		<Header>
			<HeaderLeft>
				{/* <Navigation
					menu={homeMenu}
					id='summary-top-menu'
					horizontal={deviceScreen?.width >= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE}
				/> */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					{/* <MCoin width={64} height={64} /> */}
					<img
						src={imgSrc || require('./asset/mcos2.png')}
						style={{
							width: 48,
							height: 48,
							marginRight: 12,
						}}
					/>
					<p
						style={{
							color: 'white',
							fontSize: 30,
							marginLeft: 10,
							fontFamily: 'Quicksand',
						}}>
						{title}
					</p>
				</div>
			</HeaderLeft>
			{showWallet && <CommonHeaderRight />}
		</Header>
	);
};

export default SummaryHeader;
