import NamiWalletApi from './NamiApi';
import CCVaultWalletApi from './CCVaultApi';
import FlintWalletApi from './FlintApi';

class WalletGlobal {
	get loader() {
		return this._loader;
	}

	get api() {
		return this.__api;
	}

	async getAsyncApi(type) {
		if (!this.__loader) {
			this.__loader = {};
		}
		if (this.__walletType !== type) {
			this.__api = undefined;
		}
		this.__walletType = type;

		if (this.__loader[type]) {
			this.__api = this.__loader[type];
			return this.__loader[type];
		}
		switch (type) {
			case 1:
				this.__loader[type] = new NamiWalletApi();
				break;
			case 2:
				this.__loader[type] = new CCVaultWalletApi();
				break;
			case 3:
				this.__loader[type] = new FlintWalletApi();
				break;
			default:
				break;
		}
		console.log('getAsyncApi type', type);
		console.log('getAsyncApi', this.__loader);
		this.__api = this.__loader[type];
		return this.__loader[type];
	}
}
export default new WalletGlobal();
export * from './Utils';
