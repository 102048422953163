import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	metadata: null,
};

const depositSlice = createSlice({
	name: 'deposit',
	initialState,
	reducers: {
		setMetadata: (state, { payload }) => {
			state.metadata = payload;
		},
	},
});

export const { setMetadata } = depositSlice.actions;

export default depositSlice.reducer;
