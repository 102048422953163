import React from 'react';

import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';

import logo from './asset/mocossi-logo.png';
import './index.scss';

const ComingSoon = ({ title }) => {
	const _title = title || 'Coming Soon';

	return (
		<PageWrapper title='mocossi'>
			<Page>
				<div className='comingSoon'>
					<img alt='' src={logo} />
					<h2>{_title}</h2>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ComingSoon;
