import * as airDropApi from './AirDrop';
import * as voteApi from './vote';
import * as withdrawalApi from './withdraw';
import * as authApi from './auth';

const ApiClient = {
	...airDropApi,
	...voteApi,
	...authApi,
};

export default ApiClient;

export { withdrawalApi };
