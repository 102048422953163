import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
});

instance.interceptors.response.use(function ({ data }) {
	return data;
});

export default instance;
