import {
	Address,
	BaseAddress,
	MultiAsset,
	Assets,
	ScriptHash,
	Costmdls,
	Language,
	CostModel,
	AssetName,
	TransactionUnspentOutput,
	TransactionUnspentOutputs,
	TransactionOutput,
	Value,
	TransactionBuilder,
	TransactionBuilderConfigBuilder,
	TransactionOutputBuilder,
	LinearFee,
	BigNum,
	BigInt,
	TransactionHash,
	TransactionInputs,
	TransactionInput,
	TransactionWitnessSet,
	Transaction,
	PlutusData,
	PlutusScripts,
	PlutusScript,
	PlutusList,
	Redeemers,
	Redeemer,
	RedeemerTag,
	Ed25519KeyHashes,
	ConstrPlutusData,
	ExUnits,
	Int,
	NetworkInfo,
	EnterpriseAddress,
	TransactionOutputs,
	hash_transaction,
	hash_script_data,
	hash_plutus_data,
	ScriptDataHash,
	Ed25519KeyHash,
	NativeScript,
	StakeCredential,
	GeneralTransactionMetadata,
	encode_json_str_to_metadatum,
	hash_auxiliary_data,
	AuxiliaryData,
} from '@emurgo/cardano-serialization-lib-asmjs';

let Buffer = require('buffer/').Buffer;

//copy
const protocolParams = {
	linearFee: {
		minFeeA: '44',
		minFeeB: '155381',
	},
	minUtxo: '34482',
	poolDeposit: '500000000',
	keyDeposit: '2000000',
	maxValSize: 5000,
	maxTxSize: 16384,
	priceMem: 0.0577,
	priceStep: 0.0000721,
	coinsPerUtxoWord: '34482',
};

const initTransactionBuilder = async () => {
	const txBuilder = TransactionBuilder.new(
		TransactionBuilderConfigBuilder.new()
			.fee_algo(
				LinearFee.new(
					BigNum.from_str(protocolParams.linearFee.minFeeA),
					BigNum.from_str(protocolParams.linearFee.minFeeB),
				),
			)
			.pool_deposit(BigNum.from_str(protocolParams.poolDeposit))
			.key_deposit(BigNum.from_str(protocolParams.keyDeposit))
			.coins_per_utxo_word(
				BigNum.from_str(protocolParams.coinsPerUtxoWord),
			)
			.max_value_size(protocolParams.maxValSize)
			.max_tx_size(protocolParams.maxTxSize)
			.prefer_pure_change(true)
			.build(),
	);

	return txBuilder;
};

class NamiWalletApi {
	isInstalled() {
		return !!window?.cardano?.ccvault;
	}
	async isEnabled() {
		const isEnabled = await window.cardano.ccvault.isEnabled();
		if (isEnabled) {
			this.api = await window.cardano.ccvault.enable();
		}
		return isEnabled;
	}

	async enable() {
		this.api = await window.cardano.ccvault.enable();
	}

	async getAddress() {
		const raw = await this.api.getUsedAddresses();
		const rawFirst = raw[0];
		const usedAddress = Address.from_bytes(
			Buffer.from(rawFirst, 'hex'),
		).to_bech32();
		console.log('getAddress', usedAddress);
		return usedAddress;
	}
	async getAssets() {
		this.utxos = [];
		const rawUtxos = await this.api.getUtxos();
		const result = [];

		for (const rawUtxo of rawUtxos) {
			const utxo = TransactionUnspentOutput.from_bytes(
				Buffer.from(rawUtxo, 'hex'),
			);
			const input = utxo.input();
			const txid = Buffer.from(
				input.transaction_id().to_bytes(),
				'utf8',
			).toString('hex');
			const txindx = input.index();
			const output = utxo.output();
			const amount = output.amount().coin().to_str(); // ADA amount in lovelace
			const multiasset = output.amount().multiasset();
			let multiAssetStr = '';

			if (multiasset) {
				const keys = multiasset.keys(); // policy Ids of thee multiasset
				const N = keys.len();
				for (let i = 0; i < N; i++) {
					const policyId = keys.get(i);
					const policyIdHex = Buffer.from(
						policyId.to_bytes(),
						'utf8',
					).toString('hex');
					// console.log(`policyId: ${policyIdHex}`);
					const assets = multiasset.get(policyId);
					if (!assets) {
						continue;
					}
					const assetNames = assets.keys();
					const K = assetNames.len();
					for (let j = 0; j < K; j++) {
						const assetName = assetNames.get(j);
						const assetNameString = Buffer.from(
							assetName.name(),
							'utf8',
						).toString();

						const multiassetAmt = multiasset.get_asset(
							policyId,
							assetName,
						);

						const assetNameHex = Buffer.from(
							assetName.name(),
							'utf8',
						).toString('hex');
						// const multiassetAmt = multiasset.get_asset(policyId, assetName);
						// multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.
						// ${assetNameHex} (${assetNameString})`;
						// console.log(assetNameString)
						// console.log(`Asset Name: ${assetNameHex}`);
						result.push({
							unit: `${policyIdHex}.${assetNameString}`,
							quantity: multiassetAmt.to_str(),
						});
					}
				}
			}

			const obj = {
				txid: txid,
				txindx: txindx,
				amount: amount,
				str: `${txid} #${txindx} = ${amount}`,
				multiAssetStr: multiAssetStr,
				TransactionUnspentOutput: utxo,
			};
			this.utxos.push(utxo);
		}
		// console.log(">>>>>>>>>>>>>>>>>", result);
		return result;
	}
	getTxUnspentOutputs = async () => {
		let txOutputs = TransactionUnspentOutputs.new();
		for (const utxo of this.utxos) {
			txOutputs.add(utxo);
		}
		return txOutputs;
	};

	// @ts-ignore
	async send({
		address,
		amount = 0,
		assets = [],
		metadata = null,
		metadataLabel,
		ownerAddress,
		strategy,
		cbor,
	}) {
		const txBuilder = await initTransactionBuilder();
		const shelleyOutputAddress = Address.from_bech32(address);
		const shelleyChangeAddress = Address.from_bech32(ownerAddress);

		const generalTxMeta = GeneralTransactionMetadata.new();
		generalTxMeta.insert(
			BigNum.from_str(metadataLabel),
			encode_json_str_to_metadatum(JSON.stringify(metadata), 0),
		);
		txBuilder.set_metadata(generalTxMeta);

		const aux_data = AuxiliaryData.new();
		aux_data.set_metadata(generalTxMeta);
		txBuilder.set_auxiliary_data(aux_data);

		let txOutputBuilder = TransactionOutputBuilder.new();
		txOutputBuilder = txOutputBuilder.with_address(shelleyOutputAddress);
		txOutputBuilder = txOutputBuilder.next();

		let multiAsset = _makeMultiAsset(assets);

		txOutputBuilder = txOutputBuilder.with_coin_and_asset(
			BigNum.from_str(`${Math.round(amount * 1000000)}`),
			multiAsset,
		);
		const txOutput = txOutputBuilder.build();
		txBuilder.add_output(txOutput);
		// Find the available UTXOs in the wallet and
		// us them as Inputs
		// const txUnspentOutputs = await this.getTxUnspentOutputs();
		// txBuilder.add_inputs_from(txUnspentOutputs, strategy);
		// txBuilder.add_change_if_needed(shelleyChangeAddress);

		console.log('cbor', cbor);
		let tx;
		let transactionWitnessSet = TransactionWitnessSet.new();
		if (!!cbor) {
			const bytes = Buffer.from(
				// eslint-disable-next-line max-len
				cbor,
				'hex',
			);
			// const cborSignedHex = Buffer.from(tx.to_bytes()).toString("hex");
			tx = Transaction.from_bytes(bytes);
		} else {
			const txUnspentOutputs = await this.getTxUnspentOutputs();
			txBuilder.add_inputs_from(txUnspentOutputs, strategy);
			txBuilder.add_change_if_needed(shelleyChangeAddress);
			const txBody = txBuilder.build();
			transactionWitnessSet = TransactionWitnessSet.new();
			tx = Transaction.new(txBody, transactionWitnessSet, aux_data);
		}

		let txVkeyWitnesses = await this.api.signTx(
			Buffer.from(tx.to_bytes(), 'utf8').toString('hex'),
			true,
		);
		txVkeyWitnesses = TransactionWitnessSet.from_bytes(
			Buffer.from(txVkeyWitnesses, 'hex'),
		);

		transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

		const signedTx = Transaction.new(
			tx.body(),
			transactionWitnessSet,
			tx.auxiliary_data(),
		);

		const submittedTxHash = await this.api.submitTx(
			Buffer.from(signedTx.to_bytes(), 'utf8').toString('hex'),
		);
		console.log(submittedTxHash);
		return submittedTxHash;
	}
	async getBalance() {
		const balanceCBORHex = await this.api.getBalance();
		const balance = Value.from_bytes(Buffer.from(balanceCBORHex, 'hex'))
			.coin()
			.to_str();
		return balance;
	}
	async getRewardAddresss() {
		const raw = await this.api.getRewardAddresses();
		const rawFirst = raw[0];
		const rewardAddress = Address.from_bytes(
			Buffer.from(rawFirst, 'hex'),
		).to_bech32();
		return rewardAddress;
	}
	async signData(payload, rootAddress) {
		const address = Buffer.from(
			Address.from_bech32(rootAddress).to_bytes(),
			'utf8',
		).toString('hex');
		return await this.api.signData(
			address,
			Buffer.from(JSON.stringify(payload)).toString('hex'),
		);
	}
}

function _makeMultiAsset(assets) {
	let AssetsMap = {};
	for (let asset of assets) {
		let [policy, assetName] = asset.unit.match(/.{1,56}/g);
		let quantity = asset.quantity;
		if (!Array.isArray(AssetsMap[policy])) {
			AssetsMap[policy] = [];
		}
		AssetsMap[policy].push({
			unit: assetName,
			quantity: quantity,
		});
	}
	let multiAsset = MultiAsset.new();
	for (const policy in AssetsMap) {
		const _scriptHash = ScriptHash.from_bytes(Buffer.from(policy, 'hex'));
		const assets = Assets.new();

		const _assets = AssetsMap[policy];

		for (const asset of _assets) {
			const assetName = AssetName.new(Buffer.from(asset.unit, 'hex'));
			const bigNum = BigNum.from_str(asset.quantity);

			assets.insert(assetName, bigNum);
		}
		multiAsset.insert(_scriptHash, assets);
	}
	return multiAsset;
}

export { NamiWalletApi };

export default NamiWalletApi;
