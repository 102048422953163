import { lazy } from 'react';

const Airdrop = lazy(() => import('../pages/SummaryPage'));
const ComingSoon = lazy(() => import('../pages/ComingSoon'));
// const McosDashboad = lazy(() => import('../pages/McosDashboad'));
const Withdrawal = lazy(() => import('../pages/withdrawal'));
const Vote = lazy(() => import('../pages/vote'));
const Deposit = lazy(() => import('../pages/deposit'));

const mcosRoutes = [
	{
		path: 'airdrop',
		element: <Airdrop />,
		exact: true,
	},
	{
		path: 'withdrawal/*',
		element: <Withdrawal />,
		exact: true,
	},
	{
		path: '/',
		element: <Airdrop />,
		exact: true,
	},
	{
		path: 'governance/*',
		element: <Vote />,
		exact: true,
	},
	{
		path: 'deposit/*',
		element: <Deposit />,
		exact: true,
	},
	{
		path: '*',
		element: <ComingSoon />,
	},
];
export default mcosRoutes;
