import { API_PATH } from './constants';
import instance from './instance';

export function getMetadata(params) {
	return instance.request({
		url: API_PATH.AIRDROP_METADATA,
		method: 'GET',
		params,
	});
}

export function getDaysOfNextDrop(params) {
	return instance.request({
		url: API_PATH.AIRDROP_DAYS_OF_NEXT_DROP,
		method: 'GET',
		params,
	});
}

export function getResult(params) {
	return instance.request({
		url: API_PATH.AIRDROP_RESULT,
		method: 'GET',
		params,
	});
}

export function requestAirDrop(params) {
	return instance.request({
		url: API_PATH.AIRDROP_REQUEST,
		method: 'GET',
		params,
	});
}

export function getMcosInformation() {
	return instance.request({
		url: API_PATH.MCOS_INFO,
		method: 'GET',
	});
}

export function getChartInfo() {
	return Promise.resolve({
		data: {
			ohlc: [
				{
					close: '0.03736211434288137823',
					high: '0.0373633625733836286',
					low: '0.03734151615091607115',
					open: '0.03734602057714118491',
					time: 1665410400000,
					volume: '3833.715775',
				},
				{
					close: '0.03734551994116770228',
					high: '0.03735741949770875725',
					low: '0.03734551994116770228',
					open: '0.03735741949770875725',
					time: 1665403200000,
					volume: '2964.223896',
				},
				{
					close: '0.03735783034494195626',
					high: '0.03736037066402760071',
					low: '0.03735530312913819772',
					open: '0.03735601919494788291',
					time: 1665396000000,
					volume: '1360.030556',
				},
				{
					close: '0.03735478507505108505',
					high: '0.03735966112424110062',
					low: '0.03735478507505100047',
					open: '0.03735966112424103169',
					time: 1665388800000,
					volume: '959.317114',
				},
				{
					close: '0.03736240651409103883',
					high: '0.03736321385122569275',
					low: '0.03736175610328788668',
					open: '0.03736321385122569275',
					time: 1665381600000,
					volume: '483.513041',
				},
				{
					close: '0.03736326655842532941',
					high: '0.03736452299027169429',
					low: '0.03736223219719193676',
					open: '0.03736437094592787428',
					time: 1665374400000,
					volume: '716.504129',
				},
				{
					close: '0.03736436587778845052',
					high: '0.03736436587778845052',
					low: '0.03735295412385144811',
					open: '0.03735677673947239587',
					time: 1665367200000,
					volume: '3435.158714',
				},
				{
					close: '0.03735822690805632709',
					high: '0.03736499272373645534',
					low: '0.03735822690805628813',
					open: '0.03736499272373426358',
					time: 1665360000000,
					volume: '1296.268434',
				},
				{
					close: '0.03736499272373251905',
					high: '0.03737088069088918147',
					low: '0.03736499272373251905',
					open: '0.03737088069088918147',
					time: 1665352800000,
					volume: '1096.404939',
				},
				{
					close: '0.03737088069088883663',
					high: '0.03737206239185221905',
					low: '0.03737001666837431411',
					open: '0.03737015479503239577',
					time: 1665345600000,
					volume: '686.267532',
				},
				{
					close: '0.03737015479503238257',
					high: '0.03737294218278527464',
					low: '0.03735960170950853713',
					open: '0.03736068994751319217',
					time: 1665338400000,
					volume: '3421.826894',
				},
				{
					close: '0.03736125489095721682',
					high: '0.03737469805634662847',
					low: '0.03735962525616701447',
					open: '0.03736919947172210177',
					time: 1665331200000,
					volume: '4656.21852',
				},
				{
					close: '0.03736852680032339498',
					high: '0.03737170505252104374',
					low: '0.03736629375437269601',
					open: '0.03737170505252104374',
					time: 1665324000000,
					volume: '204.499503',
				},
				{
					close: '0.03737481302724871329',
					high: '0.03750811804765988904',
					low: '0.03737481302724871329',
					open: '0.03742628388251699779',
					time: 1665316800000,
					volume: '42546.164304',
				},
				{
					close: '0.03742628388251697523',
					high: '0.03742628388251697523',
					low: '0.03729947892216699613',
					open: '0.03730616379036177469',
					time: 1665309600000,
					volume: '26252.615272',
				},
				{
					close: '0.03730640627690563732',
					high: '0.03733453370827191071',
					low: '0.03730640627690563732',
					open: '0.03733377249218849879',
					time: 1665302400000,
					volume: '5770.690878',
				},
				{
					close: '0.03733377249218843497',
					high: '0.0373338768764151707',
					low: '0.03733069498064839183',
					open: '0.03733093055758091344',
					time: 1665295200000,
					volume: '24.53227',
				},
				{
					close: '0.03733101411607269362',
					high: '0.03733778699764762534',
					low: '0.03733050661299119943',
					open: '0.03733770591108154752',
					time: 1665288000000,
					volume: '1448.324878',
				},
				{
					close: '0.03733709776463503865',
					high: '0.03733728719214073309',
					low: '0.03733538936213921302',
					open: '0.037336781187622719',
					time: 1665280800000,
					volume: '785.425133',
				},
				{
					close: '0.03733665448449436742',
					high: '0.03733665448449436742',
					low: '0.03733202961928118822',
					open: '0.03733264953733126841',
					time: 1665273600000,
					volume: '2040.977464',
				},
				{
					close: '0.03733266246068020002',
					high: '0.037339710346122978',
					low: '0.03733085364887042248',
					open: '0.03733692293343556045',
					time: 1665266400000,
					volume: '2584.837356',
				},
				{
					close: '0.03733704370854844341',
					high: '0.03733704370854844341',
					low: '0.03726647123304642195',
					open: '0.03727096134601360752',
					time: 1665259200000,
					volume: '15157.02658',
				},
				{
					close: '0.03727147789440576807',
					high: '0.03727245986077735965',
					low: '0.03726074710792036946',
					open: '0.0372612551394743522',
					time: 1665252000000,
					volume: '278.557276',
				},
				{
					close: '0.03726125513947315525',
					high: '0.03726936226883648679',
					low: '0.03725914989672920555',
					open: '0.03726936226883648679',
					time: 1665244800000,
					volume: '2436.596443',
				},
				{
					close: '0.03726936226883597141',
					high: '0.03727696318949731019',
					low: '0.03726936226883597141',
					open: '0.03727696318949731019',
					time: 1665237600000,
					volume: '1528.014383',
				},
				{
					close: '0.03727730974442366451',
					high: '0.03729586350568859989',
					low: '0.03727730974442366451',
					open: '0.03728241501161463638',
					time: 1665230400000,
					volume: '11272.09934',
				},
				{
					close: '0.03727937504483406792',
					high: '0.03728518956919171028',
					low: '0.03727937504483406792',
					open: '0.03728518956919171028',
					time: 1665223200000,
					volume: '1149.747699',
				},
				{
					close: '0.03728513882481299198',
					high: '0.03728887657779998509',
					low: '0.03728513882481299198',
					open: '0.03728650982170748638',
					time: 1665216000000,
					volume: '1451.827515',
				},
			],
		},
	});
}
