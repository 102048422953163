export const WalletStatus = {
	None: 1,
	NotInstalled: 2,
	NotEnabled: 3,
	HaveNoITO: 4,
	Enabled: 5,
};

export const WalletTypeEnum = {
	Nami: 1,
	CCVault: 2,
	None: 0,
};
