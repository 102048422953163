import { API_PATH } from './constants';
import instance from './instance';

export function voteMetadata(params) {
	// return Promise.resolve({
	// 	status: 'open',
	// 	snapshot_taken_time: 1672228800,
	// 	dao_session_name: 'ITO2 Breeding',
	// 	description:
	// 		'In this voting process, ITO1 and ITO2 holders will decide whether there should be a third generation of ITOs. In order to participate, you must hold at least an ITO1 or ITO2. We determine your voting power based on the type of Mocomon you own. In more detail, an ITO1 represents 3 votes and its offspring accounts for 1 vote (1 ITO1 = 3 votes, 1 ITO2 = 1 vote).\n\nTotal voting power is determined by the number and voting power of each ITO1 and ITO2 at the time of snapshot (28/12/2022). Suppose that at the time of snapshot, the total voting power is 57,331 (10,000*3 + 27,331*1). At the end of the voting period, there must be 28,666 votes or more (>50% of 57,331) for the result to be valid.',
	// 	start_time: 1672531200,
	// 	end_time: 1677542400,
	// 	questions: [
	// 		{
	// 			dao_session: 'DAO_SESSION_14022023',
	// 			question_id: 1,
	// 			question_type: 'single_choice_question',
	// 			question_short_text: 'ito2-breeding',
	// 			question_text: 'Do you allow ITO2 to breed?',
	// 			possible_choices: [
	// 				{
	// 					yes: 'Yes, I agree ITO2 to breed',
	// 				},
	// 				{
	// 					no: 'No, I do not agree ITO2 to breed',
	// 				},
	// 				{
	// 					postpone:
	// 						'I want to postpone my decision to 6 months later',
	// 				},
	// 			],
	// 			choose_at_least: 1,
	// 			choose_at_most: 1,
	// 		},
	// 	],
	// });
	return instance.request({
		url: API_PATH.VOTE_METADATA,
		method: 'GET',
		params,
	});
}

export function votingPower(params) {
	// return Promise.resolve({
	// 	stake_address:
	// 		'stake_test1urkk2890p84hjf224jfn8v3lwcl0wd3ukr7ck2n8h2cm4ggehlatq',
	// 	num_ito_1: 20,
	// 	num_ito_2: 10,
	// 	num_land: 0,
	// 	num_mcos: 0,
	// 	ito_1_voting_weight: 3,
	// 	ito_2_voting_weight: 1,
	// 	land_voting_weight: 0,
	// 	mcos_voting_weight: 0,
	// 	snapshot_time_in_epoch: 1672957305,
	// 	voting_power: 70,
	// 	voted: true,
	// 	tx_id: '33089e04e68ee29018f19f6c579f806f25173467e737462e147fd31005e7a1e8',
	// 	voting_time_in_epoch: 1676805117,
	// 	message: 'Voted',
	// });
	return instance.request({
		url: API_PATH.VOTE_POWER,
		method: 'GET',
		params,
	});
}

export function voteRegister(params, token) {
	return instance.request({
		url: API_PATH.VOTE_REGISTER,
		method: 'POST',
		data: params,
		headers: {
			'X-Mocossi-Bearer-Token': token,
		},
	});
}

export function voteStatus(params) {
	// return Promise.resolve({
	// 	stake_address:
	// 		'stake_test1urkk2890p84hjf224jfn8v3lwcl0wd3ukr7ck2n8h2cm4ggehlatq',
	// 	tx_id: '33089e04e68ee29018f19f6c579f806f25173467e737462e147fd31005e7a1e8',
	// 	status: 'Confirmed',
	// 	answer: [
	// 		{
	// 			'ito2-breeding': 'postpone',
	// 		},
	// 	],
	// 	message: '',
	// 	voting_time_in_epoch: 1676805117,
	// 	server_time_in_epoch: 1676803991,
	// });
	return instance.request({
		url: API_PATH.VOTE_STATUS,
		method: 'GET',
		params,
	});
}

export function voteReset(params, token) {
	return instance.request({
		url: API_PATH.VOTE_RESET,
		method: 'POST',
		data: params,
		headers: {
			'X-Mocossi-Bearer-Token': token,
		},
	});
}

export function voteResult(params) {
	// return Promise.resolve({
	// 	answers: {
	// 		by_voting_power: {
	// 			'ito2-breeding': {
	// 				yes: 15,
	// 				no: 9,
	// 				postpone: 60,
	// 			},
	// 		},
	// 		by_wallet: {
	// 			'ito2-breeding': {
	// 				yes: 1,
	// 				no: 1,
	// 				postpone: 1,
	// 			},
	// 		},
	// 	},
	// 	current_confirmed_voting_power: 84,
	// 	number_of_voted_wallets: 3,
	// 	total_voting_power: 124,
	// 	remained_time_for_voting_in_seconds: 727969,
	// 	max_voting_time_in_seconds: 5011200,
	// 	raw_data_download: '',
	// 	winning: [
	// 		{
	// 			'ito2-breeding': 'postpone',
	// 		},
	// 	],
	// });
	return instance.request({
		url: API_PATH.VOTE_RESULT,
		method: 'GET',
		params,
	});
}
