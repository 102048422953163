import Portal from 'layout/Portal/Portal';
import React, { useContext, useState } from 'react';

import { ReactComponent as SuccessIcon } from './success.svg';

import ErrorIcon from './toast-error.png';

import styles from './styles.module.scss';

const ToastContext = React.createContext<any>({});

export function useToast() {
	const { successToast, errorToast } =
		useContext(ToastContext);
	return {
		successToast,
		errorToast,
	};
}

enum ToastType {
	SUCCESS = 1,
	ERROR = 2,
}

export default function ToastProvider({ children }: any) {
	const [value, setvalue] = useState<string>('');

	const [toastType, setToastType] = useState(ToastType.SUCCESS);

	function successToast(value: string, duration = 1000) {
		setToastType(ToastType.SUCCESS);
		setvalue(value);
		setTimeout(() => {
			setvalue('');
		}, duration);
	}
	function errorToast(value: string, duration = 3000) {
		setToastType(ToastType.ERROR);
		setvalue(value);
		setTimeout(() => {
			setvalue('');
		}, duration);
	}

	function renderSuccess() {
		return (
			<div className={styles.toaster}>
				<SuccessIcon />
				<p>{value}</p>
			</div>
		);
	}
	function renderError() {
		return (
			<div className={styles.toaster}>
				<img alt='' src={ErrorIcon} />
				<p>{value}</p>
			</div>
		);
	}

	return (
		<ToastContext.Provider
			value={{ value, setvalue, successToast, errorToast }}>
			{children}
			<Portal id={'portal-toast'}>
				{!!value &&
					(toastType === ToastType.SUCCESS
						? renderSuccess()
						: renderError())}
			</Portal>
		</ToastContext.Provider>
	);
}
