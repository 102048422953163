export interface NamiWalletAsset {
	unit: string;
	quantity: string;
}

export interface NamiWalletAssetMap {
	[key: string]: NamiWalletAsset;
}

interface NamiWalletAssetResponse {
	mcos: number;
}

export function processAssets(assets: NamiWalletAsset[]) {
	return assets.reduce<NamiWalletAssetResponse>(
		(result, current) => {
			const { unit, quantity } = current;
			const mcosPolicyId = process.env.REACT_APP_MCOS_POLICY_ID;
			const mcosRate = process.env.REACT_APP_MCOS_RATE;

			if (
				mcosPolicyId &&
				mcosRate &&
				unit.endsWith(`${mcosPolicyId}.MCOS`)
			) {
				result.mcos =
					result.mcos + parseInt(quantity, 10) / parseInt(mcosRate);
				return result;
			}
			return result;
		},
		{
			mcos: 0,
		},
	);
}
