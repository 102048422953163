const API_PATH = {
	AIRDROP_METADATA: '/api/v1/mcos/airdrop/metadata',
	AIRDROP_DAYS_OF_NEXT_DROP: '/api/v1/mcos/airdrop/days-of-next-drop',
	AIRDROP_RESULT: '/api/v1/mcos/airdrop/result',
	AIRDROP_REQUEST: '/api/v1/mcos/airdrop/request',
	MCOS_INFO: '/api/v1/mcos/general/general-info',
	// MCOS_INFO: "https://mcos-testnet.mocossiplanet.com/api/v1/mcos/general/general-info",

	WITHDRAWAL_LOGIN: '/api/v1/mcos/withdrawal/login-with-otp',
	WITHDRAWAL_SETUP_OTP: '/api/v1/mcos/withdrawal/otp-setup-verification',
	WITHDRAWAL_META_DATA: '/api/v1/mcos/withdrawal/metadata',
	WITHDRAWAL_ADDRESS_BALANCE: '/api/v1/mcos/withdrawal/address-balance',
	WITHDRAWAL_REQUEST: '/api/v1/mcos/withdrawal/withdrawal-request',
	WITHDRAWAL_STATUS: '/api/v1/mcos/withdrawal/withdrawal-request-status',
	WITHDRAWAL_FEE: 'api/v1/mcos/withdrawal/withdrawal-fee',
	WITHDRAWAL_DEL: 'api/v1/mcos/withdrawal/withdrawal-request',
	WITHDRAWAL_HISTORY: 'api/v1/mcos/withdrawal/withdrawal-request-history',

	// DAO vote
	VOTE_METADATA: '/api/v1/dao/voting-metadata',
	VOTE_POWER: '/api/v1/dao/voting-power',
	VOTE_REGISTER: '/api/v1/dao/voting-register',
	VOTE_STATUS: '/api/v1/dao/voting-status',
	VOTE_RESET: '/api/v1/dao/reset-vote',
	VOTE_RESULT: '/api/v1/dao/voting-result',

	// authen
	AUTH_MESSAGE: '/api/v1/dao/auth/auth-message',
	AUTHENTICATE: '/api/v1/dao/auth/',

	// deposit
	DEPOSIT_METADATA: '/api/v1/mcos/deposit/metadata',
	DEPOSIT_PROFILE_INFO: '/api/v1/mcos/deposit/profile-info',
	DEPOSIT_HISTORY: '/api/v1/mcos/deposit/deposit_history',
};

const ERROR_REASON = {};

export { API_PATH, ERROR_REASON };
