// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export const ButtonType = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
};

const MainButton = ({
	buttonText,
	onButtonClick,
	containerClassName,
	buttonClassName,
	type = ButtonType.PRIMARY,
	buttonProps = {},
	disabled = false,
}) => {
	return (
		<div className={clsx(styles.buttonContainer, containerClassName)}>
			<button
				{...buttonProps}
				className={clsx(styles.mainButton, styles[type], buttonClassName)}
				onClick={onButtonClick}
				disabled={disabled}>
				{buttonText}
			</button>
		</div>
	);
}

export default MainButton;
