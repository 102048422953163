/* eslint-disable no-console */
/* eslint-disable camelcase */
import { createAsyncThunk, isPlainObject } from '@reduxjs/toolkit';
import Wallet from '../wallet';
import { WalletStatus } from './interface';
import LocalStorage from '../utils/LocalStorage';

import {
	updateItoAsset,
	updateWalletType,
	updateWalletInformation,
	hideLoading,
	showLoading,
} from './userSlice';
import { getCreateTransactionErrorMessage } from 'utils/app';
import { WalletTypeEnum } from 'pages/common/Headers/UserProfileButton/UserProfileButton';
import { processAssets } from 'utils/WalletUtils';

export const chooseWalletType = createAsyncThunk(
	'main/chooseWalletType',
	async ({ walletType }, { dispatch }) => {
		try {
			dispatch(showLoading());
			dispatch(updateWalletType(walletType));
			const api = await Wallet.getAsyncApi(walletType);
			if (!(await api.isInstalled())) {
				dispatch(hideLoading());
				dispatch(updateWalletType(WalletTypeEnum.None));
				return {
					walletStatus: WalletStatus.NotInstalled,
					address: '',
				};
			}
			if (!(await api.isEnabled())) {
				await api.enable();
			}
			const stakingAddress = await api.getRewardAddresss();
			const address = await api.getAddress();
			const balance = await api.getBalance();
			const assets = await api.getAssets();
			console.log('getWalletAssets: ', assets);

			const { mcos } = processAssets(assets);

			dispatch(
				updateWalletInformation({
					stakingAddress,
					address,
					balance: (balance / 1000000).toFixed(2),
					mcos,
				}),
			);
			dispatch(hideLoading());
			LocalStorage.setWithExpiry(
				'WALLET_TYPE',
				walletType,
				1000 * 60 * 24,
			);
			return {
				walletStatus: WalletStatus.Enabled,
				// address,
			};
		} catch (error) {
			console.log('chooseWalletType error', error);
			dispatch(hideLoading());
			throw error;
		} finally {
			console.log('chooseWalletType finally');
		}
	},
);
const transactionMetaDataBasic = {
	// "request-type": "breeding", // <- to know what this transaction is used for (hardcoded)
	// gen: 1, // <- generation of the Mocomons (hardcoded)
	// species: "Ito", // (hardcoded)
	unit: 'lovelace', // <- later we may have "mcos", for distingish. (hardcoded)
	pkh: 'xxxxxxxxxxx', // <- public key hash, for future feature? (optional) (window.cardano
};

export const createTransaction = createAsyncThunk(
	'main/createTransaction',
	async (params) => {
		// console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>");
		const _params = {
			// address: 'addr_test1qzk7u26v0hff6ftqvr0d4tff6elkvhlv4urxg89syrv2shjq57vl8upn6u3fdjwau045g9rnqlhgphagxw405kfpe27qq2frnc',
			// amount: 3,
			// assets: _assets,
			metadata: {
				...transactionMetaDataBasic,
				// fee: MocomonUtils.formatFee(_fee * 1000000),
				// "mocomon-1": father.id, // <- id of 1st Mocomon to breed
				// "mocomon-2": mother.id, // <- id of 2nd Mocomon to breed
				// "submitted-time": Math.floor(Date.now() / 1000),
			},
			// metadataLabel: "11106", // (hardcoded)
			// ownerAddress: "addr_test1qpuql88asujt2vprwl9wus2qt2uqf7tvggzxlgdy8lad0c7mc2xuqc4zqryu747fz7dm0w40wdc64fsd9606pksu74lqcdj36v",
			metadataLabel: '11106', // (hardcoded)
			strategy: 2, // 3
			...params,
		};
		console.log('sendBreeding params', _params);
		try {
			const txHash = await Wallet.api.send(_params);
			if (txHash && txHash.length > 0) {
				return {
					payload:
						'Your transaction has been submitted, please wait for a few minutes \n and refresh the page to check for your airdrop status.',
				};
			}
		} catch (error) {
			console.log('Error: ', error);
			const { info } = error;
			if (!!info && typeof info === 'string') {
				return {
					payload: info,
				};
			}
		}
		return {
			payload: '',
		};
	},
);

async function createVoteTransactionWithFallback(
	params,
	fallbackAda,
	shouldUseFallback,
) {
	console.log('createVoteTransaction params', params);
	try {
		const txHash = await Wallet.api.send(params);
		if (txHash && txHash.length > 0) {
			return txHash;
		}
		throw new Error('txhash empty');
	} catch (error) {
		console.log('createVoteTransaction error', error);
		const message = getCreateTransactionErrorMessage(error);
		const shouldRetry = String(message)
			.toLowerCase()
			// .includes('UTxO Balance Insufficient'.toLowerCase());
			.includes('not enough ada leftover to include');

		if (shouldRetry && params.strategy === 2) {
			console.log('Not enough ada, create transaction with strategy = 3');
			const newParams = {
				...params,
				strategy: 3,
			};
			return await createVoteTransactionWithFallback(
				newParams,
				fallbackAda,
				true,
			);
		}

		if (shouldRetry && shouldUseFallback) {
			console.log('Not enough ada, create fallback transaction');
			const { assets, ...newParams } = params;
			newParams.amount = fallbackAda;
			newParams.strategy = 2;
			return await createVoteTransactionWithFallback(
				newParams,
				fallbackAda,
				false,
			);
		}
		throw error;
	}
}

export const createVotingTransaction = createAsyncThunk(
	'main/createVotingTransaction',
	async ({ params, metadata, fallbackAda }, { rejectWithValue }) => {
		try {
			const _params = {
				metadata: {
					'request-type': 'voting',
					...metadata,
				},
				metadataLabel: '11106',
				strategy: 2,
				...params,
			};

			return await createVoteTransactionWithFallback(
				_params,
				fallbackAda,
				true,
			);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const createDepositTransaction = createAsyncThunk(
	'main/createDepositTransaction',
	async ({ params, metadata, fallbackAda }, { rejectWithValue }) => {
		try {
			const _params = {
				metadata: {
					'request-type': 'moshi-exchange',
					...metadata,
				},
				metadataLabel: '11106',
				strategy: 2,
				...params,
			};
			console.log('createDepositTransaction', _params);

			return await createVoteTransactionWithFallback(
				_params,
				fallbackAda,
				false,
			);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);
