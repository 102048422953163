import React from 'react';
import { useSelector } from 'react-redux';
import { QUESTION_TYPE_INFO } from './VoteList';

function checkIsSelected(targetAnswer, value) {
	if (targetAnswer?.length > 0) {
		return targetAnswer.includes(value);
	}
	return targetAnswer === value;
}

function Choice({ choice, handleChangeVote, question }) {
	const { answers } = useSelector((state) => state.vote);
	const targetAnswer = answers[question.question_short_text] || [];
	const [key, value] = Object.entries(choice)[0];

	return (
		<li onClick={() => handleChangeVote(key, question)}>
			<img
				src={
					checkIsSelected(targetAnswer, key)
						? QUESTION_TYPE_INFO[question.question_type]?.checked
						: QUESTION_TYPE_INFO[question.question_type]?.nonCheck
				}
				alt=''
			/>
			<span>{value}</span>
		</li>
	);
}

export default Choice;
