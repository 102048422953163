import instance from './instance';
import { API_PATH } from './constants';

export function postLogin(data) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_LOGIN,
		method: 'POST',
		data,
		headers: header(),
	});
}
export function postSetupOTP(data) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_SETUP_OTP,
		method: 'POST',
		data,
		headers: header(),
	});
}
export function postRequest(data) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_REQUEST,
		method: 'POST',
		data,
		headers: header(),
	});
}

export function getMetadata(params) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_META_DATA,
		method: 'GET',
		params,
		headers: header(),
	});
}
export function getAddressBalance(params) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_ADDRESS_BALANCE,
		method: 'GET',
		params,
		headers: header(),
	});
}
// export function getMetadata(params) {
// 	return instance.request({
// 		url: API_PATH.WITHDRAWAL_META_DATA,
// 		method: 'GET',
// 		params,
// 	});
// }
export function getRequest(params) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_REQUEST,
		method: 'GET',
		params,
		headers: header(),
	});
}
export function getStatus(params) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_STATUS,
		method: 'GET',
		params,
		headers: header(),
	});
}

export function getFee(params) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_FEE,
		method: 'GET',
		params,
		headers: header(),
	});
}

export function deleteRequest(params) {
	return instance.request({
		url: API_PATH.WITHDRAWAL_DEL,
		method: 'DELETE',
		params,
		headers: header(),
	});
}

export function history() {
	return instance.request({
		url: API_PATH.WITHDRAWAL_HISTORY,
		method: 'GET',
		headers: header(),
	});
}

function header() {
	if (sessionStorage.getItem('mocossi_token')) {
		return {
			'X-Mocossi-Bearer-Token': sessionStorage.getItem('mocossi_token'),
		};
	}
	return {};
}

// X-Mocossi-Bearer-Token
