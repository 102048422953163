import { API_PATH } from './constants';
import instance from './instance';

export function authMessage(params) {
	return instance.request({
		url: API_PATH.AUTH_MESSAGE,
		method: 'GET',
		params,
	});
}

export function authenticate(params) {
	return instance.request({
		url: API_PATH.AUTHENTICATE,
		method: 'POST',
		data: params,
	});
}
