import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import ThemeContext from '../../contexts/themeContext';
import Brand from '../Brand/Brand';
import Navigation from '../Navigation/Navigation';

import useAsideTouch from '../../hooks/useAsideTouch';

import { ReactComponent as OneIcon } from './assets/one.svg';
import { ReactComponent as TwoIcon } from './assets/two.svg';

import { ReactComponent as WithdrawalIc } from './assets/withdraw.svg';
import { ReactComponent as VoteIc } from './assets/vote.svg';
import { ReactComponent as DepositIc } from './assets/deposit.svg';

export const dashboardMenu_ = {
	// summary: {
	// 	id: 'summary',
	// 	text: 'Dashboard',
	// 	path: '/',
	// 	icon: OneIcon,
	// 	subMenu: null,
	// },

	dashboard: {
		id: 'airdrop',
		text: 'Airdrop',
		path: '/',
		icon: TwoIcon,
		subMenu: null,
	},
	withdrawal: {
		id: 'withdrawal',
		text: 'Withdrawal',
		path: 'withdrawal',
		icon: WithdrawalIc,
		subMenu: null,
	},
	vote: {
		id: 'vote',
		text: 'Governance',
		path: 'governance',
		icon: VoteIc,
		subMenu: null,
	},
	deposit: {
		id: 'deposit',
		text: 'Top up',
		path: 'deposit',
		icon: DepositIc,
		subMenu: null,
	},
};

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close':
							!touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open':
							touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand
						asideStatus={asideStatus}
						setAsideStatus={setAsideStatus}
					/>
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu_} id='aside-dashboard' />
				</div>
			</motion.aside>
		</>
	);
};

export default Aside;
