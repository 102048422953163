import DashboardHeader2 from '../pages/common/Headers/DashboardHeader2';

const headers = [
	// {
	// 	path: '/',
	// 	element: (
	// 		<DashboardHeader2 title='General statistics' showWallet={false} />
	// 	),
	// 	exact: true,
	// },
	{
		path: '/',
		element: (
			<DashboardHeader2 title='Airdrop' />
		),
		exact: true,
	},
	{
		path: 'airdrop',
		element: <DashboardHeader2 title='Airdrop' />,
		exact: true,
	},
	{
		path: '/withdrawal/*',
		element: (
			<DashboardHeader2 title='$MCOS Withdrawal' showWallet={false} />
		),
		exact: true,
	},
	{
		path: '/governance/*',
		element: <DashboardHeader2 title='Governance' showWallet={false} />,
		exact: true,
	},
	{
		path: '/deposit/*',
		element: (
			<DashboardHeader2
				title='Mo$hi Top-up'
				showWallet={false}
				imgSrc={require('pages/withdrawal/assets/withdraw_ic1.svg').default}
			/>
		),
		exact: true,
	},
];

export default headers;
