import classNames from 'classnames';
import MainButton from 'pages/SummaryPage/components/MainButton';
import { VOTE_STATUS } from 'pages/vote/const';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Choice from './Choice';
import styles from './styles.module.scss';
import { createVotingTransaction } from 'redux/asyncAction';
import { selectUserState } from 'redux/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { setAnswers } from 'redux/voteSlice';
import ApiClient from 'api';
import { useToast } from 'pages/withdrawal/Toaster';
import { Buffer } from 'buffer';
import { getCreateTransactionErrorMessage } from 'utils/app';

export const QUESTION_TYPE = {
	SINGLE_CHOICE: 'single_choice_question',
	MULTIPLE_CHOICE: 'multiple_choice_question',
};

export const QUESTION_TYPE_INFO = {
	[QUESTION_TYPE.SINGLE_CHOICE]: {
		nonCheck: require('../../assets/non-check.png'),
		checked: require('../../assets/checked.png'),
	},
	[QUESTION_TYPE.MULTIPLE_CHOICE]: {
		nonCheck: require('../../assets/non-check-multiple.png'),
		checked: require('../../assets/checked-multiple.png'),
	},
};

function VoteList({ voteStatus, onGetVotePower }) {
	const { answers } = useSelector((state) => state.vote);
	const { metadata, votingPower } = useSelector((state) => state.vote);
	const dispatch = useDispatch();
	const { address, stakingAddress, bearerToken } =
		useSelector(selectUserState);
	const { errorToast } = useToast();

	async function handleVote() {
		try {
			console.log('answers', answers);
			const userAnswer = Object.keys(answers).map((key) => {
				return {
					[key]: answers[key],
				};
			});
			console.log('userAnswer', userAnswer);
			if (userAnswer.length === 0) {
				return;
			}
			const {
				identifier,
				cold_wallet,
				wallet_address,
				fallback_identifier,
			} = JSON.parse(Buffer.from(bearerToken, 'base64').toString());
			const transactionParams = {
				address: wallet_address,
				amount: 2,
				ownerAddress: wallet_address,
			};
			if (!!cold_wallet) {
				transactionParams.assets = [
					{
						unit: identifier,
						quantity: '1',
					},
				];
			}
			const resultAction = await dispatch(
				createVotingTransaction({
					params: transactionParams,
					metadata: {
						num_ito1: votingPower.num_ito_1,
						num_ito2: votingPower.num_ito_2,
						num_land: votingPower.num_land,
						num_mcos: votingPower.num_mcos,
						ito1_weight: votingPower.ito_1_voting_weight,
						ito2_weight: votingPower.ito_2_voting_weight,
						land_weight: votingPower.land_voting_weight,
						mcos_weight: votingPower.mcos_voting_weight,
						answers: userAnswer,
					},
					fallbackAda: fallback_identifier || 30,
				}),
			);
			const txHash = unwrapResult(resultAction);
			console.log('txHash', txHash);
			if (!txHash) {
				return;
			}

			const response = await ApiClient.voteRegister(
				{
					wallet_address: address,
					stake_address: stakingAddress,
					tx_id: txHash,
					answers: userAnswer,
					voting_time_in_epoch: Number.parseInt(Date.now() / 1000),
				},
				bearerToken,
			);
			console.log('response', response);
			onGetVotePower && onGetVotePower();
		} catch (error) {
			console.log('vote error', error);
			const message = getCreateTransactionErrorMessage(error);
			errorToast(message);
		}
	}

	function handleChangeVote(key, question) {
		if (voteStatus !== VOTE_STATUS.CAN_VOTE) {
			return;
		}

		dispatch(
			setAnswers({
				value: key,
				question,
			}),
		);
	}

	return (
		<div
			className={classNames('col-xl-5', styles.border, {
				[styles.disable]: voteStatus === VOTE_STATUS.CAN_NOT_VOTE,
			})}>
			<div className={styles.wrapper}>
				{metadata.questions.map((question) => (
					<div key={question.question_id}>
						<h2>{question.question_text}</h2>
						<div className={styles.optionList}>
							<ul>
								{question.possible_choices.map(
									(choice, index) => (
										<Choice
											key={index}
											choice={choice}
											handleChangeVote={handleChangeVote}
											question={question}
										/>
									),
								)}
							</ul>
						</div>
					</div>
				))}
				<MainButton
					buttonText='VOTE'
					containerClassName={styles.voteButtonContainer}
					buttonClassName={styles.voteButton}
					disabled={!(voteStatus === VOTE_STATUS.CAN_VOTE)}
					onButtonClick={handleVote}
				/>
			</div>
		</div>
	);
}

export default VoteList;
