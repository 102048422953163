import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isShowLoading: false,
};

const mainSlice = createSlice({
	name: 'main',
	initialState,
	reducers: {
		showLoading: (state) => {
			console.log('showLoading');
			state.isShowLoading = true;
		},
		hideLoading: (state) => {
			console.log('hideLoading');
			state.isShowLoading = false;
		},
	},
});

export const { showLoading, hideLoading } = mainSlice.actions;

export default mainSlice.reducer;
