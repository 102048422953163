import React from 'react';

const LoadingContext = React.createContext({
	setLoading: (value: boolean) => {},
});

export function useLoading() {
	const { setLoading } = React.useContext(LoadingContext);

	function showLoading() {
		setLoading(true);
	}
	function hideLoading() {
		setLoading(false);
	}
	return {
		showLoading,
		hideLoading,
	};
}

export { LoadingContext };
