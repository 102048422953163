export function decimalString(num, decimalLength = 6) {
	const [integer, decimal] = decimalSplit(num, decimalLength);
	return `${integer}${decimal}`;
}

export function decimalSplit(num, decimalLength) {
	if (!num || num === 0) {
		return ['0', ''];
	}
	const normal = num.toString().replace(/[, ]/, '');
	if (!/^\d*\.?\d*$/.test(normal)) {
		return ['0', ''];
	}
	let [integer, decimal = ''] = normal.split('.');
	if (!!decimal) {
		decimal = decimal.replace(/0*$/, '');
		if (decimal.length > decimalLength) {
			decimal = decimal.slice(0, decimalLength);
		}
		if (parseInt(decimal, 10) > 0) {
			decimal = `.${decimal}`;
		} else {
			decimal = '';
		}
	}
	const result = integer
		.split('')
		.reverse()
		.reduce((acc, numB, i) => numB + (i && !(i % 3) ? ',' : '') + acc, '');

	// console.log("decimalString output", result, decimal);

	return [result, decimal];
}
export function getCreateTransactionErrorMessage(error) {
	const message = getMessageFromError(error);
	if (
		message.includes(
			'Not enough ADA leftover to include non-ADA assets in a change address',
		)
	) {
		// eslint-disable-next-line max-len
		return 'Failed to create transaction. Your wallet may have too many NFTs. Send some ADA to your wallet may help.';
	}
	return message;
}

function getMessageFromError(error) {
	if (typeof error === 'string' && error.length < 150) {
		return error;
	}
	if (error.info && error.info.length < 150) {
		return error.info;
	}
	if (error.message && error.message.length < 150) {
		return error.message;
	}
	return 'An error occurred, please try again later.';
}
