import { createSlice } from '@reduxjs/toolkit';
import { WalletStatus, WalletTypeEnum } from './interface';

import { chooseWalletType, createTransaction } from './asyncAction';

const initialState = {
	walletType: WalletTypeEnum.None,
	walletStatus: WalletStatus.None,
	address: '',
	stakingAddress: '',
	balance: -1,
	isShowLoading: false,
	transactionStatus: '',
	bearerToken: '',
	mcos: 0,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateWalletStatus: (state, { payload }) => {
			state.walletStatus = payload;
		},
		updateWalletType: (state, { payload }) => {
			state.walletType = payload;
			state.address = '';
			state.stakingAddress = '';
			state.balance = -1;
			state.mcos = 0;
		},
		updateWalletInformation: (state, { payload }) => {
			const { balance, stakingAddress, address, mcos } = payload;
			if (balance !== undefined) {
				state.balance = balance;
			}
			if (stakingAddress !== undefined) {
				state.stakingAddress = stakingAddress;
			}
			if (address !== undefined) {
				state.address = address;
			}
			if (mcos !== undefined) {
				state.mcos = mcos;
			}
		},
		showLoading: (state) => {
			console.log('showLoading');
			state.isShowLoading = true;
		},
		hideLoading: (state) => {
			console.log('hideLoading');
			state.isShowLoading = false;
		},
		resetWallet: () => initialState,
		setBearerToken: (state, { payload }) => {
			state.bearerToken = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(chooseWalletType.fulfilled, (state, { payload }) => {
			// state.address = payload.address;
			state.walletStatus = payload.walletStatus;
			state.isDoneSetupWallet = true;
		});

		builder.addCase(createTransaction.fulfilled, (state, { payload }) => {
			state.transactionStatus = payload?.payload;
		});
	},
});

export const {
	updateWalletStatus,
	updateItoAsset,
	updateWalletType,
	updateWalletInformation,
	showLoading,
	hideLoading,
	resetWallet,
	setBearerToken,
} = userSlice.actions;

export const selectUserState = (state) => state.user;
export default userSlice.reducer;
