import React from 'react';
import loadingIndicator from './loading.png';
import styles from './styles.module.scss';

function FullPageLoading() {
	return (
		<div className={styles.container}>
			<div className={styles.loading}>
				<img src={loadingIndicator} alt='' />
			</div>
		</div>
	);
}

export default FullPageLoading;
