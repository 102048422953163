/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Oval, FidgetSpinner } from 'react-loader-spinner';
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import styles from './styles.module.scss';

import nami from './assets/nami.svg';
import eternl from './assets/eternl.svg';
import tick from './assets/tick.svg';
import MainButton from '../MainButton';
import flint from './assets/flint.png';
import userLogo from './assets/user-logo.png';
import arrowIcon from './assets/arrow-down.svg';

import { selectUserState } from '../../../../redux/userSlice';
import { chooseWalletType } from '../../../../redux/asyncAction';
import LocalStorage from 'utils/LocalStorage';

export const WalletTypeEnum = {
	Nami: 1,
	CCVault: 2,
	Flint: 3,
	None: 0,
};
export const WalletNameEnum = {
	[WalletTypeEnum.Nami]: 'Nami',
	[WalletTypeEnum.CCVault]: 'Eternl',
	[WalletTypeEnum.Flint]: 'Flint',
};
const MAINNET_ID = '';

const customStyles = {
	menuList: (provided, state) => ({
		...provided,
		borderRadius: 8,
		// width: 200,
	}),
	menu: (provided, state) => ({
		...provided,
		borderRadius: 8,
		width: 170,
	}),
	option: (provided, state) => ({
		...provided,
		// color: "#ffffff",
		fontFamily: 'Quicksand',
		// font-style: normal;
		fontWeight: 700,
		fontSize: '14px',
		// line-height: 17px;
		// /* identical to box height */

		// display: flex;
		// align-items: center;
		backgroundColor: 'transparent',

		color: '#000000',

		display: 'flex',
		// backgroundColor: "#392435",
		// width: "90%",
		// marginLeft: "5%",
		height: 50,
		padding: 0,
		alignItems: 'center',
		// justifyContent: "space-between",
		cursor: 'pointer',
		// width: 200,
	}),
	container: () => ({
		// backgroundColor: 'red',
		alignItems: 'center',
	}),
	control: () => ({
		display: 'flex',
		borderRadius: 8,
		color: '#ffffff',
		cursor: 'pointer',
		alignItems: 'center',
		width: 54,
		height: 44,
	}),
	input: (provided) => ({
		...provided,
		color: '#ffffff',
	}),
};

export const options = [
	{ value: WalletTypeEnum.Nami, label: 'Nami wallet', icon: nami },
	{ value: WalletTypeEnum.CCVault, label: 'Eternl wallet', icon: eternl },
	{ value: WalletTypeEnum.Flint, label: 'Flint wallet', icon: flint },
];

const WalletIcon = {
	[WalletTypeEnum.Nami]: nami,
	[WalletTypeEnum.CCVault]: eternl,
	[WalletTypeEnum.Flint]: flint,
};

const UserProfileButton = ({ onClick, className }) => {
	const dispatch = useDispatch();

	const { walletType, balance, isShowLoading } = useSelector(selectUserState);

	const walletIcon = WalletIcon[walletType];

	let wrapperClassName;

	let controlElement;

	if (walletType === WalletTypeEnum.None) {
		wrapperClassName = clsx(styles.wrapper, className);
		controlElement = <MainButton buttonText='Connect Wallet' />;
	} else {
		wrapperClassName = clsx(
			styles.wrapper,
			styles.wrapper___style,
			className,
		);
		controlElement = (
			<>
				<img src={walletIcon} alt='' className={styles.walletIcon} />
				<img src={arrowIcon} alt='' className={styles.arrowIcon} />
			</>
		);
	}

	useEffect(() => {
		setTimeout(() => {
			const walletType = LocalStorage.getWithExpiry('WALLET_TYPE');
			if (walletType === WalletTypeEnum.CCVault) {
				dispatch(
					chooseWalletType({ walletType: WalletTypeEnum.CCVault }),
				);
				return;
			}
			if (walletType === WalletTypeEnum.Nami) {
				dispatch(chooseWalletType({ walletType: WalletTypeEnum.Nami }));
				return;
			}
			if (walletType === WalletTypeEnum.Flint) {
				dispatch(
					chooseWalletType({ walletType: WalletTypeEnum.Flint }),
				);
				return;
			}
		}, 1000);
	}, []);

	return (
		<div className={wrapperClassName}>
			<Select
				isSearchable={false}
				styles={customStyles}
				// classNamePrefix={""}
				// className={"react-select"}
				// defaultValue={defaultWallet}
				// width={180}
				options={options}
				// setValue={(ValueType, ActionTypes) => null}
				onChange={(e) => {
					// chooseWalletType
					if (!e?.value) {
						return;
					}
					dispatch(chooseWalletType({ walletType: e.value }));
					// dispatch(
					//   updateFilter({ sort_by: "bred_count", sort_order: e?.value }),
					// );
				}}
				// defaultValue={0}
				components={{
					// SelectContainer: () => null,
					Placeholder: () => null,
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					// SingleValue: ({ children, ...rest }) => (
					//   <components.SingleValue {...rest}>
					//     {children}
					//   </components.SingleValue>
					// ),
					SingleValue: () => null,
					Control: ({ children, ...rest }) => (
						<components.Control {...rest}>
							{controlElement}

							{children}
						</components.Control>
					),
					Option: ({ children, data, isSelected, ...rest }) => (
						<components.Option
							{...rest}
							data={data}
							isSelected={isSelected}>
							<img
								src={data.icon}
								alt=''
								className={styles.optionWalletIcon}
							/>
							{children}

							{walletType === data.value && (
								<img
									alt=''
									src={tick}
									className={styles.tick}
								/>
							)}
						</components.Option>
					),
				}}
			/>

			{walletType !== WalletTypeEnum.None && (
				<>
					<div className={styles.separator} />
					<div className={styles.right} onClick={onClick}>
						<img
							src={userLogo}
							alt=''
							className={styles.userLogo}
						/>
						<span className={styles.balance}>
							{balance < 0 ? '' : balance} ADA{' '}
						</span>
						{!!isShowLoading && (
							<Oval
								height={25}
								width={25}
								color='#dc1c98'
								// wrapperStyle={{}}
								wrapperClass=''
								visible={true}
								ariaLabel='oval-loading'
								secondaryColor='#f65f70'
								strokeWidth={5}
								strokeWidthSecondary={5}
								wrapperStyle={{
									// position: 'absolute',
									display: 'flex',
									right: 10,
									marginLeft: 10,
								}}
							/>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default UserProfileButton;
