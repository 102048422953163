import Cip30 from './cip30';

class CCVaultApi extends Cip30 {
	// eslint-disable-next-line class-methods-use-this
	isInstalled() {
		return !!this.getWalletNamespace();
	}

	async isEnabled() {
		const walletNamespace = this.getWalletNamespace();
		const isEnabled = await walletNamespace.isEnabled();
		console.log('isEnabled', isEnabled);
		if (isEnabled) {
			this.api = await walletNamespace.enable();
		}
		return isEnabled;
	}

	async enable() {
		const walletNamespace = this.getWalletNamespace();
		this.api = await walletNamespace.enable();
	}

	getWalletNamespace() {
		if (window?.cardano?.eternl) {
			return window.cardano.eternl;
		}
		return window?.cardano?.ccvault;
	}
}
export default CCVaultApi;
